import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ShellModule } from "./shell/shell.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { msalAuthConfiguration } from "./auth/oauth";

import { FormsModule } from "@angular/forms";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  EventType,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { AuthService } from "./auth/service/auth.service";
import { AppStore } from "./app.store";
import { User } from "./auth/model/user";
import { AppService } from "./app.service";
import { SettingsGuard } from "./guards/settings/settings.guard";
import { AdminGuard } from "./guards/admin/admin.guard";
import { SuperAdminGuard } from "./guards/super-admin/super-admin.guard";
import { PrettyPrintPipe } from "./pipes/pretty-print/pretty-print.pipe";

/**
 * MSAL Dependencies Reference links:
 *
 *  1. https://www.npmjs.com/package/@azure/msal-angular
 *  2. https://www.npmjs.com/package/@azure/msal-browser
 *  3. https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_angular.html
 *  4. https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html
 *
 */

const isInternetExplorer =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

/**
 * Factory function will be used in providers for `MSAL_INSTANCE` injection token.
 *
 * For more details refer -> https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_angular.html
 *
 * @returns PublicClientApplication instance. The PublicClientApplication class is the object exposed by the library to perform authentication and authorization functions in Single Page Applications
 * to obtain JWT tokens as described in the OAuth 2.0 Authorization Code Flow with PKCE specification.
 */
export const MSALInstanceFactory = (): IPublicClientApplication => {
  return new PublicClientApplication({
    auth: {
      clientId: msalAuthConfiguration.msalClientID,
      authority: msalAuthConfiguration.msalAuthority,
      knownAuthorities: msalAuthConfiguration.msalKnownAuthorities,
      redirectUri: msalAuthConfiguration.msalRedirectURI,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: msalAuthConfiguration.msalLogoutURI,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isInternetExplorer, // set to true for IE 11. Remove this line for Angular Universal implementation
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
};

/**
 * Factory function will be used in providers for `MSAL_GUARD_CONFIG` injection token.
 *
 * More details refer -> https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_angular.html#msal_guard_config
 *
 * @returns a MsalGuardConfiguration instance - A set of options specifically for the Angular guard.
 */
export const MSALGuardConfigFactory = (): MsalGuardConfiguration => {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: msalAuthConfiguration.msalB2CScopes,
    },
    loginFailedRoute: msalAuthConfiguration.msalLoginFailedRoute,
  };
};

/**
 * Factory function will be used in providers for `MSAL_INTERCEPTOR_CONFIG` injection token
 *
 * @returns a MsalInterceptorConfiguration instance - A set of options specifically for the Angular interceptor.
 */
export const MSALInterceptorConfigFactory =
  (): MsalInterceptorConfiguration => {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(
      msalAuthConfiguration.msalGraphURL,
      msalAuthConfiguration.msalB2CScopes
    );

    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  };

/**
 * A logger function for MSAL.
 * Use this to configure the logging that MSAL does
 * @param logLevel : Log message level (Error = 0, Warning = 1, Info = 2, Verbose = 3, Trace = 4)
 * @param message : string
 */
export const loggerCallback = (logLevel: LogLevel, message: string) => {};

@NgModule({
  declarations: [AppComponent, PrettyPrintPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShellModule,
    HttpClientModule,
    FormsModule,
    MsalModule,
    FontAwesomeModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2000 },
    },
    SettingsGuard,
    AdminGuard,
    SuperAdminGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public subscription: Subscription = new Subscription();
  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private appService: AppService,
    private appStore: AppStore
  ) {
    console.log("app module");
    let users = this.msalService.instance.getAllAccounts();
    if (users.length > 0) {
      this.getTokenDetails(users[0]);
    }

    this.msalService.instance.addEventCallback((event: any) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        this.getTokenDetails(event.payload.account);
      }
    });

    this.msalService.instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = this.msalService.instance.getActiveAccount();

        if (!account) {
          // redirect anonymous user to login page
          this.authService.signIn();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * Function to get the authorization token after successful sign in.
   * @param accountDetails will has the signed in user details received from MSAL
   */
  public getTokenDetails(accountDetails: any) {
    this.msalService.instance.setActiveAccount(accountDetails);
    let reqPayloadForAcquireTokenSilent = {
      scopes: msalAuthConfiguration.msalB2CScopes,
    };
    this.msalService
      .acquireTokenSilent(reqPayloadForAcquireTokenSilent)
      .subscribe(
        (res: any) => {
          console.log("Setup User", res);
          this.appStore.user = new User();
          this.appStore.user.displayName = res?.account?.name || null;
          this.appStore.user.email = res?.account?.username || null;
          this.appStore.user.avatar = "/assets/shell/default-profile.svg";
          this.appStore.user.roles = res?.account?.idTokenClaims?.roles || [];
          this.appStore.user.isAuthenticated = true;
          this.appStore.user.isAdmin =
            res?.account?.idTokenClaims?.roles.includes("NBL_YMS_ADMIN")
              ? true
              : false;
          this.appStore.user.isSuperAdmin =
            res?.account?.idTokenClaims?.roles.includes("NBL_YMS_SUPER_ADMIN")
              ? true
              : false;

          /**
           * Once received the response, we can trigger the application APIs from here.
           */
          console.log("Triggered Settings API");
          this.subscription.add(
            this.appService.getConfigs().subscribe((response: any) => {
              localStorage.setItem("yms_session", response.device._id);
              localStorage.setItem(
                "acid_session",
                response.device.app_center_id
              );
              response.settings.COUNTRY_CODES = JSON.parse(
                response.settings.COUNTRY_CODES
              );
              response.settings.FUNCTION_CODES = JSON.parse(
                response.settings.FUNCTION_CODES
              );
              response.settings.ADMIN_USERS = JSON.parse(
                response.settings.ADMIN_USERS
              );
              response.settings.SUPER_ADMIN_USERS = JSON.parse(
                response.settings.SUPER_ADMIN_USERS
              );
              this.appStore.settings = response.settings;
              console.log("Load Settings Data");
            })
          );
        },
        (error) => {
          this.authService.signIn();
        }
      );
  }
  ngOnDestroy() {
    /* Unsubscribing the Observable objects */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
